import axios from "axios";
import { sendPatch } from "../utils";
import { TicketStatus, TicketStatusCategory, TicketStatusId } from "../tickets";

export type TicketStatusConfigurationId = string & { __brand: "TicketStatusConfigurationId" };
export type TicketStatusConfiguration = {
    id: TicketStatusConfigurationId;
    name: string;
    statuses: TicketStatus[];
};

export type TicketStatusMigration = {
    fromId: TicketStatusId;
    toId: TicketStatusId;
};

export enum TicketStatusDeletionResult {
    Deleted,
    DeleteAfterMigration,
}

export type TicketStatusEdit = Pick<TicketStatus, "name" | "category" | "color" | "description">;

export type StatusApi = {
    getStatusConfigurations(): Promise<TicketStatusConfiguration[]>;
    createStatusConfiguration(name: string): Promise<TicketStatusConfiguration>;
    editStatusConfiguration(config: TicketStatusConfigurationId, name: string): Promise<void>;
    deleteStatusConfiguration(config: TicketStatusConfigurationId): Promise<void>;

    createStatus(
        config: TicketStatusConfigurationId,
        name: string,
        category: TicketStatusCategory,
        color: string | null,
        description: string | null
    ): Promise<TicketStatus>;
    editStatus(
        config: TicketStatusConfigurationId,
        statusId: TicketStatusId,
        name: string,
        category: TicketStatusCategory,
        color: string | null,
        description: string | null
    ): Promise<void>;
    patchStatus(
        config: TicketStatusConfigurationId,
        status: TicketStatus,
        patch: Partial<TicketStatusEdit>
    ): Promise<void>;
    migrateStatuses(
        configId: TicketStatusConfigurationId,
        migrations: TicketStatusMigration[]
    ): Promise<boolean>;
    deleteStatus(
        config: TicketStatusConfigurationId,
        status: TicketStatusId,
        migrations: TicketStatusMigration[]
    ): Promise<TicketStatusDeletionResult>;
};

export const statusApi: StatusApi = {
    async getStatusConfigurations() {
        const { data: configs } = await axios.get<TicketStatusConfiguration[]>(
            "/api/v0/project-configurations/statuses"
        );
        return configs;
    },
    async createStatusConfiguration(name) {
        const { data: config } = await axios.post<TicketStatusConfiguration>(
            `/api/v0/project-configurations/statuses`,
            {
                name,
            }
        );
        return config;
    },
    async editStatusConfiguration(config, name) {
        await axios.put(`/api/v0/project-configurations/statuses/${config}`, { name });
    },
    async deleteStatusConfiguration(config) {
        await axios.delete(`/api/v0/project-configurations/statuses/${config}`);
    },

    async createStatus(config, name, category, color, description) {
        const { data: status } = await axios.post<TicketStatus>(
            `/api/v0/project-configurations/statuses/${config}/statuses`,
            { name, category, color, description }
        );
        return status;
    },
    async editStatus(config, statusId, name, category, color, description) {
        await axios.put(`/api/v0/project-configurations/statuses/${config}/statuses/${statusId}`, {
            name,
            category,
            color,
            description,
        });
    },
    async patchStatus(config, status, patch) {
        const edited = {
            ...status,
            ...patch,
        };

        const { compare } = await import("fast-json-patch");

        const data = compare(status, edited);
        await sendPatch(
            `/api/v0/project-configurations/statuses/${config}/statuses/${status.id}`,
            data
        );
    },
    async migrateStatuses(configId, migrations) {
        const resp = await axios.post(
            `/api/v0/project-configurations/statuses/${configId}/migrate`,
            migrations
        );

        return resp.status == 200;
    },
    async deleteStatus(config, status, migrations) {
        const { data } = await axios.delete<TicketStatusDeletionResult>(
            `/api/v0/project-configurations/statuses/${config}/statuses/${status}`,
            { data: migrations }
        );
        return data;
    },
};
