import axios, { AxiosError } from "axios";
import { sendFile, sendPatch } from "./utils";
import { ProjectId, ProjectKey, ProjectVersionId } from "./projects";
import { User, UserId } from "./users";
import { DualType } from "@/typeUtils";
import { OrganizationId } from "./organizations";
import { CustomDataValueItem } from "./customData";

export type TicketSearchRequest = {
    text: string | null;
    project: ProjectId | null;

    assignee: UserId | null;
    assigned: boolean | null;

    reporter: UserId | null;

    priorities: PriorityId[] | null;

    statuses: TicketStatusId[] | null;

    types: TicketTypeId[] | null;

    labels: LabelId[] | null;

    resolutions: ResolutionId[] | null;
    resolved: boolean | null;

    affectVersions: ProjectVersionId[] | null;
    hasAffectVersion: boolean | null;

    fixVersions: ProjectVersionId[] | null;
    hasFixVersion: boolean | null;

    dueDate: Date | null;

    order: TicketSearchOrder | null;
    direction: TicketSearchDirection | null;
};

export enum TicketSearchOrder {
    Created,
    Title,
    Priority,
    DueDate,
}

export enum TicketSearchDirection {
    Ascending,
    Descending,
}

export type TicketSearchResult = {
    id: TicketId;
    key: TicketKey;
    title: string;
    priorityId: PriorityId;
    ticketTypeId: TicketTypeId;
    ticketStatusId: TicketStatusId;
    assignedToId: UserId | null;
    resolved: boolean;
    dueDate: Date | null;
};

export type SearchResult = {
    tickets: TicketSearchResult[];
    total: number;
};

export type TicketId = string & { __brand: "TicketId" };
export type TicketKey = `${ProjectKey}-${number}` & { __brand: "TicketKey" };
export type Ticket = {
    id: TicketId;
    key: TicketKey;
    number: number;
    title: string;
    description: string | null;
    pendingDescriptionImport: boolean;
    pendingStatusMigration: boolean;
    created: Date;
    dueDate?: Date | null;
    resolved: Date | null;
    priorityId: PriorityId;
    resolutionId: ResolutionId | null;
    ticketStatusId: TicketStatusId;
    ticketTypeId: TicketTypeId;
    projectId: ProjectId;
    assignedToId: UserId | null;
    reportedById: UserId;
    parentId: TicketId | null;
    affectVersions: ProjectVersionId[];
    fixVersions: ProjectVersionId[];
    labels: LabelId[];
};

export type TicketEdit = Pick<
    Ticket,
    | "title"
    | "description"
    | "priorityId"
    | "ticketStatusId"
    | "ticketTypeId"
    | "reportedById"
    | "parentId"
    | "assignedToId"
>;

type TicketResponse = Omit<Ticket, "created" | "resolved"> & {
    created: string;
    resolved: string | null;
};

export type AttachmentId = string & { __brand: "AttachmentId" };
export type Attachment = {
    id: AttachmentId;
    created: Date;
    uploaderId: UserId;
    fileName: string;
    fileSize: number;
    url: string;
    thumbnailUrl: string | null;
};

type AttachmentResponse = Omit<Attachment, "created"> & {
    created: string;
};

export type TicketCommentId = string & { __brand: "TicketCommentId" };
export type TicketComment = {
    id: TicketCommentId;
    userId: UserId;
    created: Date;
    text: string;
    lastEdit: TicketCommentEdit | null;
};

export type TicketCommentEditId = string & { __brand: "TicketCommentEditId" };
export type TicketCommentEdit = {
    id: TicketCommentEditId;
    date: Date;
    userId: UserId;
};

export type TicketCommentEditDetail = TicketCommentEdit & {
    oldText: string;
};

type TicketCommentEditResponse = Omit<TicketCommentEdit, "date"> & {
    date: string;
};

type TicketCommentEditDetailResponse = Omit<TicketCommentEditDetail, "date"> & {
    date: string;
};

type TicketCommentResponse = Omit<TicketComment, "created" | "edits"> & {
    created: string;
    edits: TicketCommentEditResponse[];
};

export type TicketLinks = {
    incoming: Map<TicketLinkTypeId, TicketSearchResult[]>;
    outgoing: Map<TicketLinkTypeId, TicketSearchResult[]>;
    bidirectional: Map<TicketLinkTypeId, TicketSearchResult[]>;
};

export type TicketLinkTypeId = string & { __brand: "TicketLinkTypeId" };
export type TicketLinkType = {
    id: TicketLinkTypeId;
    name: string;
    bidirectional: boolean;
    ticketLabel: string;
    relatedLabel: string;
};

type TicketLinkResponse = {
    incoming: Array<{
        linkTypeId: TicketLinkTypeId;
        tickets: TicketSearchResult[];
    }>;
    outgoing: Array<{
        linkTypeId: TicketLinkTypeId;
        tickets: TicketSearchResult[];
    }>;
    bidirectional: Array<{
        linkTypeId: TicketLinkTypeId;
        tickets: TicketSearchResult[];
    }>;
};

export type LabelId = string & { __brand: "LabelId" };
export type Label = {
    id: LabelId;
    usedBy: number;
    name: string;
};

export type PriorityId = string & { __brand: "PriorityId" };
export type Priority = DualType<
    {
        id: PriorityId;
        usedBy: number;
        name: string;
        order: number;
        description: string | null;
    },
    { icon: string; iconColor: string }
>;

export type ResolutionId = string & { __brand: "ResolutionId" };
export type Resolution = {
    id: ResolutionId;
    usedBy: number;
    name: string;
    description: string | null;
};

export enum TicketStatusCategory {
    ToDo,
    InProgress,
    Done,
}

export type TicketStatusId = string & { __brand: "TicketStatusId" };
export type TicketStatus = {
    id: TicketStatusId;
    usedBy: number;
    name: string;
    category: TicketStatusCategory;
    color: string | null;
    description: string | null;
    pendingDeletion: boolean;
};

export type TicketTypeId = string & { __brand: "TicketTypeId" };
export type TicketType = DualType<
    {
        id: TicketTypeId;
        usedBy: number;
        name: string;
        description: string | null;
    },
    {
        icon: string;
        iconColor: string;
    }
>;

export type TicketWebLinkId = string & { __brand: "TicketWebLinkId" };
export type TicketWebLink = {
    id: TicketWebLinkId;
    name: string | null;
    url: string;
};

export enum TicketListUserPermissionLevels {
    Unauthorized = -1,
    Unknown = 0,

    CanView = 10,

    CanEdit = 20,

    Creator = 100,
}

export type TicketListChangeLogItem = {
    ticketId?: TicketId;
    field: "add" | "remove" | "note" | "purpose" | "description";
    from?: string;
    to?: string;
};

export type TicketListChangeLog = {
    user: UserId;
    date: Date;
    changes: TicketListChangeLogItem[];
};

export type TicketListId = string & {
    __brand: "TicketListId";
};

export type TicketListTicketModel = {
    title: string;
    key: string;
    priority: Pick<Priority, "icon" | "iconColor" | "name" | "order">;
    assignee: Pick<User, "displayName" | "userName">;
    description: string;
    status: Pick<TicketStatus, "name" | "category" | "color">;
};

export type TicketListTicket = {
    ticketId: TicketId;
    note?: string;
    order?: number;
    ticket?: TicketListTicketModel;
};

export type TicketList = {
    id: TicketListId;
    name: string;
    purpose: string | null;
    description: string | null;
    tickets: TicketListTicket[];
    projectId?: ProjectId | undefined;
    organizationId?: OrganizationId | undefined;
    creator: UserId;
    created: Date;
    changeLogs: TicketListChangeLog[];
};

export type TicketListStub = Pick<TicketList, "id" | "name" | "projectId" | "organizationId"> & {
    count: number;
    permission?: TicketListUserPermissionLevels | null;
    ticketIds?: TicketId[] | undefined;
};

export type TicketListUpdateModel = {
    listId: TicketListId;
    add?: TicketListTicket[] | undefined;
    remove?: TicketId[] | undefined;
    name?: string | undefined;
    purpose?: string | undefined;
    description?: string | undefined;
    order?:
        | {
              ticketId: TicketId;
              order: number;
          }[]
        | undefined;
};

export type TicketListUserPermission = {
    userId: UserId;
    ticketListId: TicketListId;
    permission: TicketListUserPermissionLevels | null;
};

export type TicketApi = {
    search(
        request: TicketSearchRequest,
        page: number | null,
        perPage: number | null
    ): Promise<SearchResult>;
    get(id: TicketId | TicketKey): Promise<Ticket>;
    getByKey(key: string): Promise<Ticket | null>;

    create(
        project: ProjectId,
        title: string,
        type: TicketTypeId,
        priority: PriorityId,
        status: TicketStatusId,
        reporter: UserId,
        parent: TicketId | null,
        assignedTo: UserId | null,
        customData: CustomDataValueItem[] | null
    ): Promise<Ticket>;

    edit(id: TicketId, edit: TicketEdit): Promise<void>;
    patch(ticket: Ticket, patch: Partial<TicketEdit>): Promise<void>;
    resolve(id: TicketId, status: TicketStatusId, resolution: ResolutionId | null): Promise<void>;
    delete(id: TicketId, deleteChildren: boolean): Promise<void>;

    getDueDate(id: TicketId): Promise<Date | null>;
    changeDueDate(id: TicketId, dueDate: string | null): Promise<void>;

    getChildren(id: TicketId): Promise<TicketSearchResult[]>;

    getAttachments(id: TicketId): Promise<Attachment[]>;
    uploadAttachment(id: TicketId, file: File): Promise<Attachment>;
    deleteAttachment(ticket: TicketId, attachment: AttachmentId): Promise<void>;

    getComments(id: TicketId): Promise<TicketComment[]>;
    postComment(id: TicketId, text: string): Promise<TicketComment>;
    getCommentEdits(ticket: TicketId, comment: TicketCommentId): Promise<TicketCommentEditDetail[]>;
    editComment(
        ticket: TicketId,
        comment: TicketCommentId,
        text: string
    ): Promise<TicketCommentEdit>;
    deleteComment(ticket: TicketId, comment: TicketCommentId): Promise<void>;

    getLinks(id: TicketId): Promise<TicketLinks>;
    addLink(id: TicketId, type: TicketLinkTypeId, linked: TicketId): Promise<void>;
    removeLink(id: TicketId, type: TicketLinkTypeId, linked: TicketId): Promise<void>;

    getWebLinks(id: TicketId): Promise<TicketWebLink[]>;
    createWebLink(ticketId: TicketId, url: string, name?: string): Promise<TicketWebLink>;
    deleteWebLink(ticketId: TicketId, webLinkId: TicketWebLinkId): Promise<void>;

    getAffectVersions(id: TicketId): Promise<ProjectVersionId[]>;
    setAffectVersions(id: TicketId, versions: ProjectVersionId[]): Promise<void>;

    getFixVersions(id: TicketId): Promise<ProjectVersionId[]>;
    setFixVersions(id: TicketId, versions: ProjectVersionId[]): Promise<void>;

    getLabels(): Promise<Label[]>;
    setLabels(id: TicketId, labels: LabelId[]): Promise<void>;

    getLinkTypes(): Promise<TicketLinkType[]>;
    getPriorities(): Promise<Priority[]>;
    getResolutions(): Promise<Resolution[]>;
    getStatuses(): Promise<TicketStatus[]>;
    getTypes(): Promise<TicketType[]>;

    getList(listId: TicketListId): Promise<TicketList>;
    getLists(opts?: {
        organizationId?: OrganizationId;
        projectId?: ProjectId;
    }): Promise<TicketListStub[]>;
    createList(
        list: Omit<TicketList, "id" | "creator" | "created" | "changeLogs">
    ): Promise<TicketList>;
    updateList(opts: TicketListUpdateModel): Promise<TicketList>;
    deleteList(listId: TicketListId): Promise<boolean>;
    getListPermissions(listId: TicketListId): Promise<TicketListUserPermission[]>;
    setListPermissions(listId: TicketListId, items: TicketListUserPermission[]): Promise<boolean>;
};

function formatTicket(ticket: TicketResponse): Ticket {
    return {
        ...ticket,
        created: new Date(ticket.created),
        resolved: ticket.resolved ? new Date(ticket.resolved) : null,
    };
}

function formatAttachment(attachment: AttachmentResponse): Attachment {
    return {
        ...attachment,
        created: new Date(attachment.created),
    };
}

function formatComment(comment: TicketCommentResponse): TicketComment {
    return {
        ...comment,
        created: new Date(comment.created),
        lastEdit: comment.lastEdit
            ? { ...comment.lastEdit, date: new Date(comment.lastEdit.date) }
            : null,
    };
}

export const ticketApi: TicketApi = {
    async search(request, page, perPage) {
        const params = new URLSearchParams();
        if (page != null) params.set("page", page.toString());
        if (perPage != null) params.set("perPage", perPage.toString());

        const { data: result } = await axios.post<SearchResult>(
            `/api/v0/tickets/search?${params}`,
            request
        );

        return result;
    },
    async get(id) {
        const { data: ticket } = await axios.get<TicketResponse>(`/api/v0/tickets/${id}`);
        return formatTicket(ticket);
    },
    async getByKey(key) {
        try {
            const { data: ticket } = await axios.get<TicketResponse>(`/api/v0/tickets/key/${key}`);
            return formatTicket(ticket);
        } catch (ex) {
            if (ex instanceof AxiosError && ex.response?.status === 404) {
                return null;
            } else {
                throw ex;
            }
        }
    },

    async create(
        project,
        title,
        type,
        priority,
        status,
        reporter,
        parent,
        assignedTo,
        customData = null
    ) {
        const { data: id } = await axios.post<TicketId>(`/api/v0/projects/${project}/tickets`, {
            title,
            priority,
            type,
            status,
            reporter,
            parent,
            assignedTo,
            customData,
        });

        return await this.get(id);
    },

    async edit(ticket, edit) {
        await axios.post(`/api/v0/tickets/${ticket}`, edit);
    },
    async patch(ticket, patch) {
        const edited = {
            ...ticket,
            ...patch,
        };

        const { compare } = await import("fast-json-patch");

        const data = compare(ticket, edited);
        await sendPatch(`/api/v0/tickets/${ticket.id}`, data);
    },
    async resolve(id, ticketStatusId, resolutionId) {
        await axios.post(`/api/v0/tickets/${id}/resolution`, {
            ticketStatusId,
            resolutionId,
        });
    },

    async delete(id, deleteChildren) {
        await axios.delete(`/api/v0/tickets/${id}`, {
            params: { deleteChildren },
        });
    },

    async getDueDate(id: TicketId): Promise<Date | null> {
        const { data: dueDate } = await axios.get<Date | null>(`/api/v0/tickets/${id}/dueDate`);
        return dueDate ? new Date(dueDate) : null;
    },

    async changeDueDate(id: string, dueDate: string | null): Promise<void> {
        try {
            await axios.put(`/api/v0/tickets/${id}/dueDate`, dueDate, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json, text/plain, */*",
                },
            });
        } catch (error) {
            console.error("Failed to update due date:", error);
            throw error;
        }
    },

    async getChildren(id) {
        const { data: children } = await axios.get<TicketSearchResult[]>(
            `/api/v0/tickets/${id}/children`
        );
        return children;
    },

    async getAttachments(id) {
        const { data: attachments } = await axios.get<AttachmentResponse[]>(
            `/api/v0/tickets/${id}/attachments`
        );

        return attachments.map(formatAttachment);
    },
    async uploadAttachment(id, file) {
        const attachment = await sendFile<AttachmentResponse>(
            `/api/v0/tickets/${id}/attachments`,
            file
        );

        return formatAttachment(attachment);
    },
    async deleteAttachment(ticket, attachment) {
        await axios.delete(`/api/v0/tickets/${ticket}/attachments/${attachment}`);
    },

    async getAffectVersions(id) {
        const { data: versions } = await axios.get<ProjectVersionId[]>(
            `/api/v0/tickets/${id}/versions/affect`
        );
        return versions;
    },
    async setAffectVersions(id, versions) {
        await axios.put(`/api/v0/tickets/${id}/versions/affect`, { versions });
    },

    async getFixVersions(id) {
        const { data: versions } = await axios.get<ProjectVersionId[]>(
            `/api/v0/tickets/${id}/versions/fix`
        );
        return versions;
    },
    async setFixVersions(id, versions) {
        await axios.put(`/api/v0/tickets/${id}/versions/fix`, { versions });
    },

    async getComments(id) {
        const { data: comments } = await axios.get<TicketCommentResponse[]>(
            `/api/v0/tickets/${id}/comments`
        );

        return comments.map(formatComment);
    },
    async postComment(id, text) {
        const { data: comment } = await axios.post<TicketCommentResponse>(
            `/api/v0/tickets/${id}/comments`,
            { text }
        );

        return formatComment(comment);
    },
    async getCommentEdits(ticket, comment) {
        const { data: edits } = await axios.get<TicketCommentEditDetailResponse[]>(
            `/api/v0/tickets/${ticket}/comments/${comment}/edits`
        );

        return edits.map((e) => ({ ...e, date: new Date(e.date) }));
    },
    async editComment(ticket, comment, text) {
        const { data } = await axios.put<TicketCommentEditResponse>(
            `/api/v0/tickets/${ticket}/comments/${comment}`,
            { text }
        );
        return { ...data, date: new Date(data.date) };
    },
    async deleteComment(ticket, comment) {
        await axios.delete(`/api/v0/tickets/${ticket}/comments/${comment}`);
    },

    async getLinks(id) {
        const { data } = await axios.get<TicketLinkResponse>(`/api/v0/tickets/${id}/links`);
        return {
            incoming: new Map(data.incoming.map((l) => [l.linkTypeId, l.tickets])),
            outgoing: new Map(data.outgoing.map((l) => [l.linkTypeId, l.tickets])),
            bidirectional: new Map(data.bidirectional.map((l) => [l.linkTypeId, l.tickets])),
        };
    },
    async addLink(id, type, linked) {
        await axios.put(`/api/v0/tickets/${id}/links/${type}/${linked}`);
    },
    async removeLink(id, type, linked) {
        await axios.delete(`/api/v0/tickets/${id}/links/${type}/${linked}`);
    },

    async getWebLinks(id) {
        const { data: webLinks } = await axios.get<TicketWebLink[]>(
            `/api/v0/tickets/${id}/webLinks`
        );
        return webLinks;
    },
    async createWebLink(ticketId, url, name) {
        const { data: webLink } = await axios.post<TicketWebLink>(
            `/api/v0/tickets/${ticketId}/webLinks`,
            { url, name }
        );
        return webLink;
    },
    async deleteWebLink(ticketId, webLinkId) {
        await axios.delete(`/api/v0/tickets/${ticketId}/webLinks/${webLinkId}`);
    },

    async getLabels() {
        const { data: labels } = await axios.get<Label[]>("/api/v0/labels");
        return labels;
    },
    async setLabels(id, labels) {
        await axios.put(`/api/v0/tickets/${id}/labels`, { labels: labels });
    },

    async getLinkTypes() {
        const { data: linkTypes } = await axios.get<TicketLinkType[]>("/api/v0/link-types");
        return linkTypes;
    },
    async getPriorities() {
        const { data: priorities } = await axios.get<Priority[]>("/api/v0/priorities");
        return priorities;
    },
    async getResolutions() {
        const { data: resolutions } = await axios.get<Resolution[]>("/api/v0/resolutions");
        return resolutions;
    },
    async getStatuses() {
        const { data: statuses } = await axios.get<TicketStatus[]>("/api/v0/statuses");
        return statuses;
    },
    async getTypes() {
        const { data: types } = await axios.get<TicketType[]>("/api/v0/types");
        return types;
    },
    async getList(listId) {
        const { data: result } = await axios.get<TicketList>(`/api/v0/tickets/lists/${listId}`);
        return result;
    },
    async getLists(opts) {
        if (opts == null) {
            const { data: lists } = await axios.get<TicketListStub[]>(`/api/v0/tickets/lists`);
            return lists;
        } else if (
            (opts.organizationId != null && opts.projectId != null) ||
            (opts.organizationId == null && opts.projectId == null)
        )
            throw "getLists: Opts must include either organizationId OR projectId";
        else {
            const ownerType =
                opts.organizationId != null ? "org" : opts.projectId != null ? "proj" : null;
            const id = opts.organizationId ?? opts.projectId ?? null;
            const { data: lists } = await axios.get<TicketListStub[]>(
                `/api/v0/tickets/lists/${ownerType}/${id}`
            );

            return lists;
        }
    },
    async createList(list) {
        if (
            (list.organizationId != null && list.projectId != null) ||
            (list.organizationId == null && list.projectId == null)
        )
            throw "createList: List must include either organizationId OR projectId";

        try {
            const { data: result } = await axios.post<TicketList>(`/api/v0/tickets/lists`, list);

            return result;
        } catch (e) {
            if (e instanceof AxiosError)
                throw (
                    "createList: Either you do not have permission to create a list," +
                    " you do not have permission to view one of the tickets in this list," +
                    " or there was an internal server error that prevented it from being created."
                );
            else throw e;
        }
    },
    async updateList(opts) {
        const { data: result } = await axios.patch<TicketList>(
            `/api/v0/tickets/lists/${opts.listId}`,
            {
                add: opts.add,
                remove: opts.remove,
                name: opts.name,
                purpose: opts.purpose,
                description: opts.description,
                order: opts.order,
            }
        );
        return result;
    },
    async deleteList(listId) {
        const { data: result } = await axios.delete<boolean>(`/api/v0/tickets/lists/${listId}`);
        return result;
    },
    async getListPermissions(listId) {
        const { data: result } = await axios.get<TicketListUserPermission[]>(
            `/api/v0/tickets/lists/${listId}/permissions`
        );
        return result;
    },
    async setListPermissions(listId, items) {
        if (items.some((i) => i.ticketListId != listId))
            throw "Some permissions items have a different list id. Stopping for safety.";
        const { data: result } = await axios.post<boolean>(
            `/api/v0/tickets/lists/${listId}/permissions`,
            { items: items }
        );
        return result;
    },
};
