import axios from "axios";

export type AboutModel = {
    version: string | null;
};

export type AboutApi = {
    about(): Promise<AboutModel>;
};

export const aboutApi: AboutApi = {
    async about() {
        const { data: abt } = await axios.get<AboutModel>("/api/v0/about");
        return abt;
    },
};
