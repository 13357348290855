import {
    Label,
    LabelId,
    Priority,
    PriorityId,
    Resolution,
    ResolutionId,
    Ticket,
    ticketApi,
    TicketLinkType,
    TicketLinkTypeId,
    TicketSearchResult,
    TicketStatus,
    TicketStatusId,
    TicketType,
    TicketTypeId,
} from "@/api/tickets";
import { isDefined } from "@/utils";
import { defineStore } from "pinia";

export type TicketsState = {
    labels: Map<LabelId, Label>;
    linkTypes: Map<TicketLinkTypeId, TicketLinkType>;
    priorities: Map<PriorityId, Priority>;
    resolutions: Map<ResolutionId, Resolution>;
    statuses: Map<TicketStatusId, TicketStatus>;
    types: Map<TicketTypeId, TicketType>;
};

export const useTicketsStore = defineStore("tickets", {
    state: (): TicketsState => ({
        labels: new Map(),
        linkTypes: new Map(),
        priorities: new Map(),
        resolutions: new Map(),
        statuses: new Map(),
        types: new Map(),
    }),
    actions: {
        async load(force = false) {
            await this.loadLabels(force);
            await this.loadLinkTypes(force);
            await this.loadPriorities(force);
            await this.loadResolutions(force);
            await this.loadStatuses(force);
            await this.loadTypes(force);
        },
        async loadLabels(force = false) {
            if (this.$state.labels.size > 0 && !force) {
                return;
            }

            const labels = await ticketApi.getLabels();
            this.$state.labels = new Map(labels.map((l) => [l.id, l]));
        },
        async loadLinkTypes(force = false) {
            if (this.$state.linkTypes.size > 0 && !force) {
                return;
            }

            const linkTypes = await ticketApi.getLinkTypes();
            this.$state.linkTypes = new Map(linkTypes.map((lt) => [lt.id, lt]));
        },
        async loadPriorities(force = false) {
            if (this.$state.priorities.size > 0 && !force) {
                return;
            }

            const priorities = await ticketApi.getPriorities();
            this.$state.priorities = new Map(priorities.map((l) => [l.id, l]));
        },
        async loadResolutions(force = false) {
            if (this.$state.resolutions.size > 0 && !force) {
                return;
            }

            const resolutions = await ticketApi.getResolutions();
            this.$state.resolutions = new Map(resolutions.map((t) => [t.id, t]));
        },
        async loadStatuses(force = false) {
            if (this.$state.statuses.size > 0 && !force) {
                return;
            }

            const statuses = await ticketApi.getStatuses();
            this.$state.statuses = new Map(statuses.map((t) => [t.id, t]));
        },
        async loadTypes(force = false) {
            if (this.$state.types.size > 0 && !force) {
                return;
            }

            const types = await ticketApi.getTypes();
            this.$state.types = new Map(types.map((t) => [t.id, t]));
        },
        async setLabels(ticket: Ticket, updateLabels: Label[], newLabels: Label[]): Promise<void> {
            const ids = updateLabels.map((ul) => ul.id);
            ids.push(...newLabels.map((nl) => nl.id));
            await ticketApi.setLabels(ticket.id, ids);
            newLabels.forEach((nl) => {
                this.$state.labels.set(nl.id, nl);
            });
        },
    },
    getters: {
        getLabels: (state) => (ticket: Ticket) =>
            ticket.labels.map((l) => state.labels.get(l)).filter(isDefined),
        getLinkType: (state) => (link: TicketLinkTypeId) => state.linkTypes.get(link),
        getPriority: (state) => (ticket: Ticket | TicketSearchResult) =>
            state.priorities.get(ticket.priorityId),
        getResolution: (state) => (ticket: Ticket) =>
            ticket.resolutionId ? state.resolutions.get(ticket.resolutionId) : undefined,
        getStatus: (state) => (ticket: Ticket | TicketSearchResult) =>
            state.statuses.get(ticket.ticketStatusId),
        getType: (state) => (ticket: Ticket | TicketSearchResult) =>
            state.types.get(ticket.ticketTypeId),
        getDueDate: (_state) => (ticket: Ticket) =>
            ticket.dueDate ? new Date(ticket.dueDate) : null,
    },
});