import axios from "axios";
import { LabelApi, labelApi, LabelConfigurationId } from "./labels";
import { priorityApi, PriorityApi, PriorityConfigurationId } from "./priorities";
import { resolutionApi, ResolutionApi, ResolutionConfigurationId } from "./resolutions";
import {
    StatusApi,
    statusApi,
    TicketStatusConfigurationId,
    TicketStatusMigration,
} from "./statuses";
import { ticketTypeApi, TicketTypeApi, TicketTypeConfigurationId } from "./types";
import { customDataConfigApi, CustomDataConfigApi, CustomDataConfigurationId } from "./customData";

export type ProjectConfigurationId = string & { __brand: "ProjectConfigurationId" };
export type ProjectConfiguration = {
    id: ProjectConfigurationId;
    name: string;
    resolutionConfigurationId: ResolutionConfigurationId;
    ticketStatusConfigurationId: TicketStatusConfigurationId;
    ticketTypeConfigurationId: TicketTypeConfigurationId;
    priorityConfigurationId: PriorityConfigurationId;
    labelConfigurationId: LabelConfigurationId;
    projectCustomDataConfigurationId: CustomDataConfigurationId | null;
};

export type { LabelConfiguration, LabelConfigurationId } from "./labels";
export type { PriorityConfiguration, PriorityConfigurationId } from "./priorities";
export type { ResolutionConfiguration, ResolutionConfigurationId } from "./resolutions";
export type { TicketStatusConfiguration, TicketStatusConfigurationId } from "./statuses";
export type { TicketTypeConfiguration, TicketTypeConfigurationId } from "./types";

type ProjectConfigApiBase = {
    getProjectConfigurations(): Promise<ProjectConfiguration[]>;
    createProjectConfiguration(
        name: string,
        labelConfigurationId: LabelConfigurationId,
        resolutionConfigurationId: ResolutionConfigurationId,
        ticketStatusConfigurationId: TicketStatusConfigurationId,
        ticketTypeConfigurationId: TicketTypeConfigurationId,
        priorityConfigurationId: PriorityConfigurationId
    ): Promise<ProjectConfiguration>;
    editProjectConfiguration(
        config: ProjectConfiguration,
        patch: ProjectConfiguration,
        migrationModel: TicketStatusMigration[]
    ): Promise<void>;
    deleteProjectConfiguration(config: ProjectConfigurationId): Promise<void>;
};

export type ProjectConfigApi = LabelApi &
    PriorityApi &
    ResolutionApi &
    StatusApi &
    TicketTypeApi &
    CustomDataConfigApi &
    ProjectConfigApiBase;

export const projectConfigApi: ProjectConfigApi = {
    async getProjectConfigurations() {
        const { data: configs } = await axios.get<ProjectConfiguration[]>(
            "/api/v0/project-configurations"
        );
        return configs;
    },
    async createProjectConfiguration(
        name,
        labelConfigurationId,
        resolutionConfigurationId,
        ticketStatusConfigurationId,
        ticketTypeConfigurationId,
        priorityConfigurationId
    ) {
        const { data: config } = await axios.post<ProjectConfiguration>(
            "/api/v0/project-configurations",
            {
                name,
                labelConfigurationId,
                resolutionConfigurationId,
                ticketStatusConfigurationId,
                ticketTypeConfigurationId,
                priorityConfigurationId,
            }
        );
        return config;
    },
    async editProjectConfiguration(config, patch, migrations = []) {
        const edited = {
            ...config,
            ...patch,
        };

        const { compare } = await import("fast-json-patch");

        const data = { patch: compare(config, edited), migrations };

        await axios.patch(`/api/v0/project-configurations/${config.id}`, data);
    },
    async deleteProjectConfiguration(config) {
        await axios.delete(`/api/v0/project-configurations/${config}`);
    },

    ...labelApi,
    ...priorityApi,
    ...resolutionApi,
    ...statusApi,
    ...ticketTypeApi,
    ...customDataConfigApi,
};
