import { organizationApi, OrganizationId } from "@/api/organizations";
import {
    projectConfigApi,
    type LabelConfiguration,
    type LabelConfigurationId,
    type PriorityConfiguration,
    type PriorityConfigurationId,
    type ProjectConfiguration,
    type ProjectConfigurationId,
    type TicketStatusConfiguration,
    type TicketStatusConfigurationId,
    type TicketTypeConfiguration,
    type TicketTypeConfigurationId,
} from "@/api/projectConfigs";
import type {
    ResolutionConfiguration,
    ResolutionConfigurationId,
} from "@/api/projectConfigs/resolutions";
import {
    projectApi,
    type Project,
    type ProjectId,
    type ProjectTeam,
    type ProjectVersion,
    ProjectPermission,
    type ProjectKey,
    type ProjectVersionId,
    type ProjectEdit,
    MilestoneId,
    MilestoneEdit,
    Milestone,
    ProjectVersionReleaseModel,
} from "@/api/projects";
import type { Team, TeamId } from "@/api/teams";
import type { UserId } from "@/api/users";
import { isDefined } from "@/utils";
import { AxiosError } from "axios";
import { defineStore } from "pinia";
import { useTeamsStore } from "./teams";
import { useUsersStore } from "./users";
import { LabelCreateModel } from "@/api/projectConfigs/labels";
import { ticketTypeApi, TicketTypeEdit } from "@/api/projectConfigs/types";
import {
    LabelId,
    PriorityId,
    ResolutionId,
    TicketStatusCategory,
    TicketStatusId,
    TicketTypeId,
} from "@/api/tickets";
import { PriorityEdit } from "@/api/projectConfigs/priorities";
import {
    TicketStatusDeletionResult,
    TicketStatusEdit,
    TicketStatusMigration,
} from "@/api/projectConfigs/statuses";
import { useAuthStore } from "./auth";
import { DynamicFormData, DynamicFormSchemaItemTypes, DynamicFormSelectItem } from "@/components";
import {
    CustomDataConfiguration,
    CustomDataConfigurationId,
    CustomDataFieldId,
    CustomDataRule,
} from "@/api/projectConfigs/customData";
import { useTicketsStore } from "./tickets";
import { useTicketSearchStore } from "./ticketSearch";

export type SubConfigDynamicFormData = {
    id: string | null;
    name: string;
} & DynamicFormData;

export type SubConfigDataModel = {
    id: string;
    name: string;
    items: SubConfigDynamicFormData[];
};

export type ProjectsState = {
    projects: Map<ProjectId, Project>;
    projectKeys: Map<ProjectKey, Project>;
    users: Map<ProjectId, UserId[]>;
    teams: Map<ProjectId, ProjectTeam[]>;
    versions: Map<ProjectId, ProjectVersion[]>;
    milestones: Map<ProjectId, Milestone[]>;
    configs: Map<ProjectConfigurationId, ProjectConfiguration>;
    labelConfigs: Map<LabelConfigurationId, LabelConfiguration>;
    priorityConfigs: Map<PriorityConfigurationId, PriorityConfiguration>;
    resolutionConfigs: Map<ResolutionConfigurationId, ResolutionConfiguration>;
    statusConfigs: Map<TicketStatusConfigurationId, TicketStatusConfiguration>;
    typeConfigs: Map<TicketTypeConfigurationId, TicketTypeConfiguration>;
    customDataConfigs: Map<CustomDataConfigurationId, CustomDataConfiguration>;
};

function isProjectId(id: ProjectId | ProjectKey): id is ProjectId {
    return !!id.match(/[0-9]/);
}

export const useProjectsStore = defineStore("projects", {
    state: (): ProjectsState => ({
        projects: new Map(),
        projectKeys: new Map(),
        versions: new Map(),
        milestones: new Map(),
        teams: new Map(),
        users: new Map(),
        configs: new Map(),
        labelConfigs: new Map(),
        priorityConfigs: new Map(),
        resolutionConfigs: new Map(),
        statusConfigs: new Map(),
        typeConfigs: new Map(),
        customDataConfigs: new Map(),
    }),
    getters: {
        get(state) {
            return (id: ProjectId | ProjectKey) =>
                isProjectId(id) ? state.projects.get(id) : state.projectKeys.get(id);
        },
        getProjects(state) {
            const authStore = useAuthStore();
            return (permission = ProjectPermission.CanViewProject) =>
                Array.from(state.projects.values()).filter((p) =>
                    authStore.hasPermission(p.id, permission)
                );
        },
        getOrganizationProjects(state) {
            return (id: OrganizationId) =>
                Array.from(state.projects.values()).filter((p) => p.organizationId === id);
        },
        getUsers(state) {
            const userStore = useUsersStore();

            return (projectId: ProjectId) =>
                Array.from(userStore.users.values()).filter((u) =>
                    state.users.get(projectId)?.some((pu) => pu === u.id)
                );
        },
        getUsersWithPermission(state) {
            const teamStore = useTeamsStore();
            const userStore = useUsersStore();

            return (projectId: ProjectId, permission = ProjectPermission.CanViewProject) => {
                const users = state.users.get(projectId) ?? [];
                const teams = state.teams.get(projectId)?.map((t) => teamStore.get(t.teamId)) ?? [];

                return users
                    .filter((u) =>
                        teams.some(
                            (t) =>
                                t?.users.some((tu) => tu == u) &&
                                t.permissions.some(
                                    (p) =>
                                        p === permission ||
                                        p === ProjectPermission.ProjectAdministrator
                                )
                        )
                    )
                    .map((u) => userStore.get(u))
                    .filter(isDefined);
            };
        },
        getTeam(state) {
            return (projectId: ProjectId, teamId: TeamId) =>
                state.teams.get(projectId)?.find((t) => t.teamId === teamId) ?? null;
        },
        getPriorities(state) {
            return (id: ProjectId) => {
                const configId = this.get(id)?.configurationId;
                if (!configId) {
                    return [];
                }
                const priorityConfigId = state.configs.get(configId)?.priorityConfigurationId;
                if (!priorityConfigId) {
                    return [];
                }
                return state.priorityConfigs.get(priorityConfigId)?.priorities || [];
            };
        },
        getResolutions(state) {
            return (id: ProjectId) => {
                const configId = this.get(id)?.configurationId;
                if (!configId) {
                    return [];
                }
                const resolutionConfigId = state.configs.get(configId)?.resolutionConfigurationId;
                if (!resolutionConfigId) {
                    return [];
                }
                return state.resolutionConfigs.get(resolutionConfigId)?.resolutions || [];
            };
        },
        getStatuses(state) {
            return (id: ProjectId) => {
                const configId = this.get(id)?.configurationId;
                if (!configId) {
                    return [];
                }
                const statusConfigId = state.configs.get(configId)?.ticketStatusConfigurationId;
                if (!statusConfigId) {
                    return [];
                }
                return state.statusConfigs.get(statusConfigId)?.statuses || [];
            };
        },
        getTypes(state) {
            return (id: ProjectId) => {
                const configId = this.get(id)?.configurationId;
                if (!configId) {
                    return [];
                }
                const typeConfigId = state.configs.get(configId)?.ticketTypeConfigurationId;
                if (!typeConfigId) {
                    return [];
                }
                return state.typeConfigs.get(typeConfigId)?.types || [];
            };
        },
        getVersions(state) {
            return (id: ProjectId) => state.versions.get(id) || [];
        },
        getMilestones(state) {
            return (id: ProjectId) => state.milestones.get(id) || [];
        },
        getProjectConfiguration(state) {
            return (id: ProjectId) => {
                const configId = this.get(id)?.configurationId;
                if (!configId) return undefined;
                return state.configs.get(configId);
            };
        },
        getProjectLabelConfiguration(state) {
            return (id: ProjectId) => {
                const config = this.getProjectConfiguration(id);
                if (!config) return undefined;
                return state.labelConfigs.get(config.labelConfigurationId);
            };
        },
        getLabels(state) {
            return (id: ProjectId) => {
                const configId = this.get(id)?.configurationId;
                if (!configId) {
                    return [];
                }
                const labelConfigId = state.configs.get(configId)?.labelConfigurationId;
                if (!labelConfigId) {
                    return [];
                }
                return state.labelConfigs.get(labelConfigId)?.labels || [];
            };
        },
    },
    actions: {
        async load(force = false) {
            await this.loadProjects(force);
            await this.loadConfigs(force);
        },

        async loadProjects(force = false) {
            if (!force && this.$state.projects.size > 0) {
                return;
            }

            const projects = await projectApi.getProjects();
            this.$state.projects = new Map(projects.map((p) => [p.id, p]));
            this.$state.projectKeys = new Map(projects.map((p) => [p.key, p]));
        },
        setProject(project: Project) {
            this.$state.projects.set(project.id, project);
            this.$state.projectKeys.set(project.key, project);
        },
        async createProject(
            id: OrganizationId,
            name: string,
            key: string,
            configuration: ProjectConfigurationId
        ) {
            const project = await organizationApi.createProject(id, name, key, configuration);
            this.setProject(project);
            return project;
        },

        async editProject(id: ProjectId, model: ProjectEdit) {
            await projectApi.editProject(id, model);

            const project = this.get(id);
            if (project) {
                project.name = model.name;
            }
        },

        async changeOrganization(id: ProjectId, organization: OrganizationId) {
            await projectApi.changeOrganization(id, organization);

            const project = this.get(id);
            if (project) {
                project.organizationId = organization;
            }
        },

        async loadProject(id: ProjectId, force = false) {
            if (!force && this.$state.projects.has(id)) {
                await this.loadProjects(true);
            }

            await this.loadUsers(id, force);
            await this.loadTeams(id, force);
            await this.loadVersions(id, force);
            await this.loadMilestones(id, force);
        },
        async loadUsers(id: ProjectId, force = false) {
            if (!force && this.users.has(id)) {
                return;
            }

            const users = await projectApi.getUsers(id);
            this.users.set(id, users);
        },
        async loadTeams(id: ProjectId, force = false) {
            if (!force && this.teams.has(id)) {
                return;
            }

            const teams = await projectApi.getTeams(id);
            this.teams.set(id, teams);
        },
        async addTeam(project: ProjectId, team: Team) {
            await projectApi.addTeam(project, team.id);

            const teams = this.teams.get(project);
            if (!teams) {
                await this.loadTeams(project);
            } else {
                teams.push({
                    teamId: team.id,
                    organizationId: team.organizationId,
                    permissions: [],
                });
            }
        },
        async removeTeam(project: ProjectId, team: Team) {
            await projectApi.removeTeam(project, team.id);

            const teams = this.teams.get(project);
            if (!teams) {
                await this.loadTeams(project);
            } else {
                const idx = teams.findIndex((t) => t.teamId === team.id);
                teams.splice(idx, 1);
            }
        },

        async loadVersions(id: ProjectId, force = false) {
            if (!force && this.versions.has(id)) {
                return;
            }

            const versions = await projectApi.getVersions(id);
            this.versions.set(id, versions);
        },
        async createVersion(
            project: ProjectId,
            name: string,
            startDate?: Date,
            endDate?: Date,
            freezeDate?: Date
        ) {
            const version = await projectApi.createVersion(
                project,
                name,
                startDate,
                endDate,
                freezeDate
            );

            const versions = this.versions.get(project);
            if (!versions) {
                await this.loadVersions(project);
            } else {
                versions.unshift(version);
            }

            return version;
        },
        async editVersion(
            project: ProjectId,
            version: ProjectVersion,
            name: string,
            startDate: string | null,
            endDate: string | null,
            freezeDate: string | null,
            releaseDate: string | null,
            notes: string | null
        ) {
            await projectApi.editVersion(project, version, {
                name,
                startDate,
                endDate,
                freezeDate,
                releaseDate,
                notes,
            });

            const versions = this.getVersions(project);
            const v = versions.find((v) => v.id === version.id);
            if (v) {
                v.name = name;
                v.startDate = startDate ? new Date(startDate) : null;
                v.endDate = endDate ? new Date(endDate) : null;
                v.freezeDate = freezeDate ? new Date(freezeDate) : null;
                v.releaseDate = releaseDate ? new Date(releaseDate) : null;
                v.notes = notes;
            } else {
                await this.loadVersions(project);
            }
        },
        async releaseVersion(
            project: ProjectId,
            version: ProjectVersionId,
            model: ProjectVersionReleaseModel
        ) {
            await projectApi.releaseVersion(project, version, model);

            const versions = this.getVersions(project);
            const v = versions.find((v) => v.id === version);
            if (v) {
                v.releaseDate = model.releaseDate ? new Date(model.releaseDate) : null;
                v.notes = model.notes;
                if (model.migrateTickets) {
                    v.unresolvedTickets = null;
                }
            } else {
                await this.loadVersions(project);
            }
        },
        async deleteVersion(project: ProjectId, version: ProjectVersionId) {
            try {
                await projectApi.deleteVersion(project, version);
            } catch (ex) {
                if (ex instanceof AxiosError) {
                    return ex.response?.data as string;
                }
            }

            const versions = this.getVersions(project);

            versions.splice(
                versions.findIndex((v) => v.id === version),
                1
            );

            return;
        },

        async loadMilestones(project: ProjectId, force = false) {
            if (!force && this.milestones.has(project)) {
                return;
            }

            const milestones = await projectApi.getMilestones(project);
            this.milestones.set(project, milestones);
        },

        async createMilestone(project: ProjectId, milestone: Omit<Milestone, "id">) {
            const ms = await projectApi.createMilestone(
                project,
                milestone.name,
                milestone.startDate == null ? undefined : milestone.startDate,
                milestone.endDate == null ? undefined : milestone.endDate
            );

            this.milestones.set(project, (this.milestones.get(project) ?? []).concat(ms));
        },

        async editMilestone(project: ProjectId, milestone: MilestoneId, patch: MilestoneEdit) {
            const m = this.milestones.get(project)?.find((m) => m.id == milestone);
            if (m != undefined) {
                await projectApi.editMilestone(project, m, patch);
                const edit: Milestone = {
                    id: m.id,
                    name: patch.name,
                    startDate: patch.startDate != null ? new Date(patch.startDate) : null,
                    endDate: patch.endDate != null ? new Date(patch.endDate) : null,
                };
                this.milestones.set(
                    project,
                    (this.milestones.get(project) ?? [])
                        .filter((mi) => mi.id != milestone)
                        .concat(edit)
                );
            }
            await this.loadMilestones(project);
        },

        async deleteMilestone(project: ProjectId, milestone: MilestoneId) {
            await projectApi.deleteMilestone(project, milestone);
            this.milestones.set(
                project,
                (this.milestones.get(project) ?? []).filter((p) => p.id != milestone)
            );
        },

        async setPermissions(
            projectId: ProjectId,
            teamId: TeamId,
            permissions: ProjectPermission[]
        ) {
            const team = this.teams.get(projectId)?.find((t) => t.teamId === teamId);
            if (!team) {
                return;
            }

            await projectApi.setTeamPermissions(projectId, teamId, permissions);
            team.permissions = permissions;
        },

        async createLabels(labelConfigId: LabelConfigurationId, labelNames: string[]) {
            const labelConfig = this.labelConfigs.get(labelConfigId);
            if (!labelConfig) {
                return;
            }
            if (
                labelConfig.labels.map((lcl) => lcl.name).filter((n) => labelNames.includes(n))
                    .length > 0
            ) {
                return;
            }

            const model: LabelCreateModel = {
                names: labelNames.map((ln) => ({
                    name: ln,
                })),
            };
            const res = await projectConfigApi.createLabels(labelConfig.id, model);
            labelConfig.labels.push(...res);
            this.labelConfigs.set(labelConfigId, labelConfig);
            return res;
        },

        async loadConfigs(force = false) {
            await this.loadProjectConfigs(force);
            await this.loadLabelConfigs(force);
            await this.loadPriorityConfigs(force);
            await this.loadResolutionConfigs(force);
            await this.loadStatusConfigs(force);
            await this.loadTypeConfigs(force);
            await this.loadCustomDataConfigs(force);
        },
        async loadProjectConfigs(force = false) {
            if (this.$state.configs.size > 0 && !force) {
                return;
            }

            const configs = await projectConfigApi.getProjectConfigurations();
            this.$state.configs = new Map(configs.map((c) => [c.id, c]));
        },
        async createProjectConfig(
            name: string,
            labelConfigurationId: LabelConfigurationId,
            resolutionConfigurationId: ResolutionConfigurationId,
            ticketStatusConfigurationId: TicketStatusConfigurationId,
            ticketTypeConfigurationId: TicketTypeConfigurationId,
            priorityConfigurationId: PriorityConfigurationId
        ) {
            const { configs } = this;
            const newConfig = await projectConfigApi.createProjectConfiguration(
                name,
                labelConfigurationId,
                resolutionConfigurationId,
                ticketStatusConfigurationId,
                ticketTypeConfigurationId,
                priorityConfigurationId
            );

            if (configs.size < 1) {
                await this.loadProjectConfigs();
            } else {
                configs.set(newConfig.id, newConfig);
            }
        },
        async editProjectConfig(
            config: ProjectConfigurationId,
            patch: ProjectConfiguration,
            migrationModel: TicketStatusMigration[]
        ) {
            const currentConfig = this.configs.get(config);
            if (currentConfig != undefined) {
                await projectConfigApi.editProjectConfiguration(
                    currentConfig,
                    patch,
                    migrationModel
                );
            }

            if (this.configs.size > 0 && currentConfig) {
                this.configs.set(config, { ...currentConfig, ...patch });
            } else {
                await this.loadConfigs();
            }

            if (migrationModel.length > 0) {
                const ticketStore = useTicketsStore();
                const searchStore = useTicketSearchStore();
                await ticketStore.loadStatuses(true);
                await this.loadStatusConfigs(true);
                searchStore.$state.searched = false;
            }
        },
        async deleteProjectConfig(config: ProjectConfigurationId) {
            const projectConfig = this.configs.get(config);

            if (projectConfig) {
                await projectConfigApi.deleteProjectConfiguration(config);

                this.configs.delete(config);
            }
        },
        async loadLabelConfigs(force = false) {
            if (this.$state.labelConfigs.size > 0 && !force) {
                return;
            }

            const labelConfigs = await projectConfigApi.getLabelConfigurations();
            this.$state.labelConfigs = new Map(labelConfigs.map((l) => [l.id, l]));
        },
        async createLabelConfig(name: string) {
            const { labelConfigs } = this;
            const newConfig = await projectConfigApi.createLabelConfiguration(name);
            if (labelConfigs.size < 1) {
                await this.loadLabelConfigs();
            } else {
                labelConfigs.set(newConfig.id, newConfig);
            }

            return newConfig;
        },
        async editLabelConfig(config: LabelConfigurationId, name: string) {
            const labelConfig = this.labelConfigs.get(config);

            if (labelConfig) {
                await projectConfigApi.editLabelConfiguration(config, name);

                const updatedConfig: LabelConfiguration = {
                    id: config,
                    name: name,
                    labels: labelConfig.labels,
                };
                this.labelConfigs.set(config, updatedConfig);
            }
        },
        async deleteLabelConfig(config: LabelConfigurationId) {
            const labelConfig = this.labelConfigs.get(config);
            if (labelConfig) {
                await projectConfigApi.deleteLabelConfiguration(config);

                this.labelConfigs.delete(config);
            }
        },
        async editLabel(config: LabelConfigurationId, label: LabelId, name: string) {
            const labelConfig = this.labelConfigs.get(config);
            const currentLabel = labelConfig?.labels.find((l) => l.id === label);

            if (labelConfig && currentLabel) {
                await projectConfigApi.editLabel(config, label, name);

                currentLabel.name = name;

                this.labelConfigs.set(config, labelConfig);
            }
        },
        async deleteLabel(config: LabelConfigurationId, label: LabelId) {
            const labelConfig = this.labelConfigs.get(config);
            const currentLabel = labelConfig?.labels.find((l) => l.id === label);

            if (labelConfig && currentLabel) {
                await projectConfigApi.deleteLabel(config, label);
                const updatedLabels = labelConfig.labels.filter((l) => l.id !== label);

                const updatedConfig: LabelConfiguration = {
                    ...labelConfig,
                    labels: updatedLabels,
                };

                this.labelConfigs.set(config, updatedConfig);
            }
        },
        async loadPriorityConfigs(force = false) {
            if (this.$state.priorityConfigs.size > 0 && !force) {
                return;
            }

            const priorityConfigs = await projectConfigApi.getPriorityConfigurations();
            this.$state.priorityConfigs = new Map(priorityConfigs.map((p) => [p.id, p]));
        },
        async createPriorityConfig(name: string) {
            const { priorityConfigs } = this;
            const newConfig = await projectConfigApi.createPriorityConfiguration(name);
            if (priorityConfigs.size < 1) {
                await this.loadPriorityConfigs();
            } else {
                priorityConfigs.set(newConfig.id, newConfig);
            }

            return newConfig;
        },
        async editPriorityConfig(config: PriorityConfigurationId, name: string) {
            const priorityConfig = this.priorityConfigs.get(config);

            if (priorityConfig) {
                await projectConfigApi.editPriorityConfiguration(config, name);

                const editedConfig: PriorityConfiguration = {
                    id: config,
                    name: name,
                    priorities: priorityConfig.priorities,
                };

                this.priorityConfigs.set(config, editedConfig);
            }
        },
        async deletePriorityConfig(config: PriorityConfigurationId) {
            const priorityConfig = this.priorityConfigs.get(config);

            if (priorityConfig) {
                await projectConfigApi.deletePriorityConfiguration(config);

                this.priorityConfigs.delete(config);
            }
        },
        async createPriority(
            config: PriorityConfigurationId,
            name: string,
            order: number,
            icon: string,
            iconColor: string,
            description: string | null
        ) {
            const { priorityConfigs } = this;
            const priorityConfig = priorityConfigs.get(config);
            const newPriority = await projectConfigApi.createPriority(
                config,
                name,
                order,
                icon,
                iconColor,
                description
            );
            const priorities = priorityConfig?.priorities;

            if (priorityConfigs.size < 1) {
                await this.loadPriorityConfigs();
            } else {
                priorities?.forEach((priority) => {
                    if (priority.order >= order) {
                        priority.order++;
                    }
                });
                priorities?.push(newPriority);
                priorities?.sort((a, b) => b.order - a.order);
            }
        },
        async editPriority(
            config: PriorityConfigurationId,
            priority: PriorityId,
            edit: PriorityEdit
        ) {
            const { priorityConfigs } = this;
            const priorityConfig = priorityConfigs.get(config);
            const currentPriority = priorityConfig?.priorities.find((p) => p.id === priority);
            const priorities = priorityConfig?.priorities;

            if (priorityConfig && currentPriority) {
                await projectConfigApi.editPriority(config, priority, edit);

                if (currentPriority.order !== edit.order) {
                    if (currentPriority.order < edit.order) {
                        priorities?.forEach((p) => {
                            if (p.order > currentPriority.order && p.order <= edit.order) {
                                p.order--;
                            }
                        });
                    }
                    if (edit.order < currentPriority.order) {
                        priorities?.forEach((p) => {
                            if (p.order <= currentPriority.order && p.order >= edit.order) {
                                p.order++;
                            }
                        });
                    }
                }

                currentPriority.name = edit.name;
                currentPriority.order = edit.order;
                currentPriority.icon = edit.icon;
                currentPriority.iconColor = edit.iconColor;
                currentPriority.description = edit.description;

                priorities?.sort((a, b) => b.order - a.order);
                priorityConfigs.set(config, priorityConfig);
            }
        },
        async patchPriority(
            config: PriorityConfigurationId,
            priority: PriorityId,
            patch: Partial<PriorityEdit>
        ) {
            const { priorityConfigs } = this;
            const priorityConfig = priorityConfigs.get(config);
            const currentPriority = priorityConfig?.priorities.find((p) => p.id === priority);
            const priorities = priorityConfig?.priorities;

            if (priorityConfig && currentPriority) {
                await projectConfigApi.patchPriority(config, currentPriority, patch);
                if (patch.name) {
                    currentPriority.name = patch.name;
                }
                if (patch.order) {
                    const priorityWithWantedOrder = priorities?.find(
                        (p) => p.order === Number(patch.order)
                    );
                    if (currentPriority.order !== patch.order && priorityWithWantedOrder) {
                        if (currentPriority.order < patch.order) {
                            priorities?.forEach((p) => {
                                if (p.order > currentPriority.order && p.order <= patch.order!) {
                                    p.order--;
                                }
                            });
                        }
                        if (patch.order < currentPriority.order) {
                            priorities?.forEach((p) => {
                                if (p.order < currentPriority.order && p.order >= patch.order!) {
                                    p.order++;
                                }
                            });
                        }
                    }
                    currentPriority.order = patch.order;
                    priorities?.sort((a, b) => b.order - a.order);
                }
                if (patch.icon) {
                    currentPriority.icon = patch.icon;
                }
                if (patch.iconColor) {
                    currentPriority.iconColor = patch.iconColor;
                }
                if (patch.description) {
                    currentPriority.description = patch.description;
                }

                priorityConfigs.set(config, priorityConfig);
            }
        },
        async deletePriority(config: PriorityConfigurationId, priority: PriorityId) {
            const priorityConfig = this.priorityConfigs.get(config);
            const currentPriority = priorityConfig?.priorities.find((p) => p.id === priority);

            if (priorityConfig && currentPriority) {
                await projectConfigApi.deletePriority(config, priority);
                const updatedPriorities = priorityConfig.priorities.filter(
                    (p) => p.id !== priority
                );

                updatedPriorities.forEach((p) => {
                    if (p.order > currentPriority.order) {
                        p.order--;
                    }
                });

                const updatedConfig: PriorityConfiguration = {
                    ...priorityConfig,
                    priorities: updatedPriorities,
                };

                this.priorityConfigs.set(config, updatedConfig);
            }
        },
        async loadResolutionConfigs(force = false) {
            if (this.$state.resolutionConfigs.size > 0 && !force) {
                return;
            }

            const resolutionConfigs = await projectConfigApi.getResolutionConfigurations();
            this.$state.resolutionConfigs = new Map(resolutionConfigs.map((p) => [p.id, p]));
        },
        async createResolutionConfig(name: string) {
            const config = await projectConfigApi.createResolutionConfiguration(name);
            const { resolutionConfigs } = this;
            if (resolutionConfigs.size < 1) {
                await this.loadResolutionConfigs();
            } else {
                this.resolutionConfigs.set(config.id, config);
            }

            return config;
        },
        async editResolutionConfig(config: ResolutionConfigurationId, name: string) {
            const resolutionConfig = this.resolutionConfigs.get(config);
            if (resolutionConfig) {
                await projectConfigApi.editResolutionConfiguration(config, name);

                const edited: ResolutionConfiguration = {
                    id: config,
                    name: name,
                    resolutions: resolutionConfig.resolutions,
                };

                this.resolutionConfigs.set(config, edited);
            }
        },
        async deleteResolutionConfig(config: ResolutionConfigurationId) {
            const resolutionConfig = this.resolutionConfigs.get(config);
            if (resolutionConfig) {
                await projectConfigApi.deleteResolutionConfiguration(config);

                this.resolutionConfigs.delete(config);
            }
        },
        async createResolution(
            config: ResolutionConfigurationId,
            name: string,
            description: string | null
        ) {
            const { resolutionConfigs } = this;
            const newResolution = await projectConfigApi.createResolution(
                config,
                name,
                description
            );
            if (resolutionConfigs.size < 1) {
                await this.loadResolutionConfigs();
            } else {
                const resolutionConfig = resolutionConfigs.get(config);
                resolutionConfig?.resolutions.push(newResolution);
            }
        },
        async editResolution(
            config: ResolutionConfigurationId,
            resolution: ResolutionId,
            name: string,
            description: string | null
        ) {
            const { resolutionConfigs } = this;
            const resolutionConfig = resolutionConfigs.get(config);
            const currentResolution = resolutionConfig?.resolutions.find(
                (r) => r.id === resolution
            );
            if (resolutionConfig && currentResolution) {
                await projectConfigApi.editResolution(config, resolution, name, description);

                currentResolution.name = name;
                currentResolution.description = description;

                resolutionConfigs.set(config, resolutionConfig);
            }
        },
        async deleteResolution(config: ResolutionConfigurationId, resolution: ResolutionId) {
            const resolutionConfig = this.resolutionConfigs.get(config);
            const currentResolution = resolutionConfig?.resolutions.find(
                (r) => r.id === resolution
            );

            if (resolutionConfig && currentResolution) {
                await projectConfigApi.deleteResolution(config, resolution);
                const updatedResolutions = resolutionConfig.resolutions.filter(
                    (r) => r.id !== resolution
                );

                const updatedConfig: ResolutionConfiguration = {
                    ...resolutionConfig,
                    resolutions: updatedResolutions,
                };

                this.resolutionConfigs.set(config, updatedConfig);
            }
        },
        async loadStatusConfigs(force = false) {
            if (this.$state.statusConfigs.size > 0 && !force) {
                return;
            }

            const statusConfigs = await projectConfigApi.getStatusConfigurations();
            this.$state.statusConfigs = new Map(statusConfigs.map((s) => [s.id, s]));
        },
        async createStatusConfig(name: string) {
            const statusConfig = await projectConfigApi.createStatusConfiguration(name);
            const { statusConfigs } = this;
            if (!(statusConfigs.size > 0)) {
                await this.loadTypeConfigs();
            } else {
                statusConfigs.set(statusConfig.id, statusConfig);
            }

            return statusConfig;
        },
        async editStatusConfig(config: TicketStatusConfigurationId, name: string) {
            const statusConfig = this.statusConfigs.get(config);
            if (statusConfig) {
                await projectConfigApi.editStatusConfiguration(config, name);

                const edited: TicketStatusConfiguration = {
                    id: config,
                    name: name,
                    statuses: statusConfig.statuses,
                };

                this.statusConfigs.set(config, edited);
            }
        },
        async deleteStatusConfig(config: TicketStatusConfigurationId) {
            const statusConfig = this.statusConfigs.get(config);
            if (statusConfig) {
                await projectConfigApi.deleteStatusConfiguration(config);

                this.statusConfigs.delete(config);
            }
        },
        async createStatus(
            config: TicketStatusConfigurationId,
            name: string,
            category: TicketStatusCategory,
            color: string | null,
            description: string | null
        ) {
            const newStatus = await projectConfigApi.createStatus(
                config,
                name,
                category,
                color,
                description
            );
            const { statusConfigs } = this;
            if (statusConfigs.size < 1) {
                await this.loadStatusConfigs();
            } else {
                const statusConfig = statusConfigs.get(config);
                statusConfig?.statuses.push(newStatus);
                statusConfigs.get(config);
            }

            return newStatus;
        },
        async editStatus(
            config: TicketStatusConfigurationId,
            statusId: TicketStatusId,
            name: string,
            category: TicketStatusCategory,
            color: string | null,
            description: string | null
        ) {
            const statusConfig = this.statusConfigs.get(config);
            const currentStatus = statusConfig?.statuses.find((s) => s.id === statusId);
            if (statusConfig && currentStatus) {
                await projectConfigApi.editStatus(
                    config,
                    statusId,
                    name,
                    category,
                    color,
                    description
                );
                currentStatus.name = name;
                currentStatus.color = color;
                currentStatus.description = description;

                this.statusConfigs.set(config, statusConfig);
            }
        },
        async patchStatus(
            config: TicketStatusConfigurationId,
            status: TicketStatusId,
            patch: Partial<TicketStatusEdit>
        ) {
            const statusConfig = this.statusConfigs.get(config);
            const currentStatus = statusConfig?.statuses.find((s) => s.id === status);

            if (statusConfig && currentStatus) {
                await projectConfigApi.patchStatus(config, currentStatus, patch);
                if (patch.name) {
                    currentStatus.name = patch.name;
                }
                if (patch.category) {
                    currentStatus.category = patch.category;
                }
                if (patch.color) {
                    currentStatus.color = patch.color;
                }
                if (patch.description) {
                    currentStatus.description = patch.description;
                }
                this.statusConfigs.set(config, statusConfig);
            }
        },

        async migrateStatuses(
            configId: TicketStatusConfigurationId,
            migrations: TicketStatusMigration[]
        ) {
            const success = await projectConfigApi.migrateStatuses(configId, migrations);
            if (success) {
                const ticketStore = useTicketsStore();
                const searchStore = useTicketSearchStore();
                await ticketStore.loadStatuses(true);
                await this.loadStatusConfigs(true);
                searchStore.$state.searched = false;
            }
        },
        async deleteStatus(
            config: TicketStatusConfigurationId,
            status: TicketStatusId,
            migrations: TicketStatusMigration[]
        ) {
            const statusConfig = this.statusConfigs.get(config);
            const currentStatus = statusConfig?.statuses.find((s) => s.id === status);

            if (statusConfig && currentStatus) {
                const result = await projectConfigApi.deleteStatus(config, status, migrations);

                if (result == TicketStatusDeletionResult.Deleted) {
                    const updatedStatuses = statusConfig.statuses.filter((s) => s.id !== status);

                    const updatedConfig: TicketStatusConfiguration = {
                        ...statusConfig,
                        statuses: updatedStatuses,
                    };

                    this.statusConfigs.set(config, updatedConfig);
                } else {
                    const statusIndex = statusConfig.statuses.findIndex((s) => s.id == status);
                    if (statusIndex >= 0) {
                        statusConfig.statuses[statusIndex]!.pendingDeletion = true;
                    }
                }
            }
        },
        async loadTypeConfigs(force = false) {
            if (this.$state.typeConfigs.size > 0 && !force) {
                return;
            }

            const typeConfigs = await projectConfigApi.getTypeConfigurations();
            this.$state.typeConfigs = new Map(typeConfigs.map((t) => [t.id, t]));
        },
        async createTypeConfig(name: string) {
            const typeConfig = await ticketTypeApi.createTypeConfiguration(name);
            const { typeConfigs } = this;
            if (!(typeConfigs.size > 0)) {
                await this.loadTypeConfigs();
            } else {
                typeConfigs.set(typeConfig.id, typeConfig);
            }

            return typeConfig;
        },
        async editTypeConfig(config: TicketTypeConfigurationId, name: string) {
            const ticketTypeConfig = this.typeConfigs.get(config);
            if (ticketTypeConfig !== undefined) {
                await ticketTypeApi.editTypeConfiguration(config, name);

                const edited: TicketTypeConfiguration = {
                    id: config,
                    name: name,
                    types: ticketTypeConfig.types,
                };
                this.typeConfigs.set(config, edited);
            }
            await this.loadTypeConfigs();
        },
        async deleteTypeConfig(config: TicketTypeConfigurationId) {
            const ticketTypeConfig = this.typeConfigs.get(config);
            if (ticketTypeConfig !== undefined) {
                await ticketTypeApi.deleteTypeConfiguration(config);

                this.typeConfigs.delete(config);
            }
            await this.loadTypeConfigs();
        },
        async createType(
            config: TicketTypeConfigurationId,
            name: string,
            icon: string | null,
            iconColor: string | null,
            description: string | null
        ) {
            const newType = await ticketTypeApi.createType(
                config,
                name,
                icon,
                iconColor,
                description
            );
            const { typeConfigs } = this;
            if (!(typeConfigs.size > 0)) {
                await this.loadTypeConfigs();
            } else {
                const typeConfig = typeConfigs.get(config);
                typeConfig?.types.push(newType);
            }
        },
        async editType(
            config: TicketTypeConfigurationId,
            type: TicketTypeId,
            name: string,
            icon: string | null,
            iconColor: string | null,
            description: string | null
        ) {
            const ticketTypeConfig = this.typeConfigs.get(config);
            const ticketType = ticketTypeConfig?.types.find((t) => t.id === type);
            if (ticketTypeConfig && ticketType) {
                await ticketTypeApi.editType(config, type, name, icon, iconColor, description);

                ticketType.name = name;
                ticketType.icon = icon;
                ticketType.iconColor = iconColor;
                ticketType.description = description;

                this.typeConfigs.set(config, ticketTypeConfig);
            }
        },
        async patchType(
            config: TicketTypeConfigurationId,
            type: TicketTypeId,
            patch: Partial<TicketTypeEdit>
        ) {
            const ticketTypeConfig = this.typeConfigs.get(config);
            const ticketType = ticketTypeConfig?.types.find((t) => t.id === type);

            if (ticketTypeConfig && ticketType) {
                await projectConfigApi.patchType(config, ticketType, patch);
                if (patch.name) {
                    ticketType.name = patch.name;
                }
                if (patch.icon) {
                    ticketType.icon = patch.icon;
                }
                if (patch.iconColor) {
                    ticketType.iconColor = patch.iconColor;
                }
                if (patch.description) {
                    ticketType.description = patch.description;
                }
                this.typeConfigs.set(config, ticketTypeConfig);
            }
        },
        async deleteType(config: TicketTypeConfigurationId, type: TicketTypeId) {
            const ticketTypeConfig = this.typeConfigs.get(config);
            const currentType = ticketTypeConfig?.types.find((t) => t.id === type);

            if (ticketTypeConfig && currentType) {
                await ticketTypeApi.deleteType(config, type);
                const updatedTypes = ticketTypeConfig.types.filter((t) => t.id !== type);

                const updatedConfig: TicketTypeConfiguration = {
                    ...ticketTypeConfig,
                    types: updatedTypes,
                };

                this.typeConfigs.set(config, updatedConfig);
            }
        },

        async loadCustomDataConfigs(force: boolean = false) {
            if (this.$state.customDataConfigs.size > 0 && !force) {
                return;
            }

            const customDataConfigs = await projectConfigApi.getCustomDataConfigurations();
            this.$state.customDataConfigs = new Map(customDataConfigs.map((t) => [t.id, t]));
        },
        async createCustomDataConfig(name: string) {
            const config = await projectConfigApi.createCustomDataConfiguration(name);
            this.$state.customDataConfigs.set(config.id, config);
            return config;
        },
        async editCustomDataConfig(config: CustomDataConfigurationId, name: string) {
            await projectConfigApi.editCustomDataConfiguration(config, name);
            const cfg = this.$state.customDataConfigs.get(config);
            if (cfg) {
                cfg.name = name;
                this.$state.customDataConfigs.set(cfg.id, cfg);
            }
        },
        async deleteCustomDataConfig(config: CustomDataConfigurationId) {
            await projectConfigApi.deleteCustomDataConfiguration(config);
            this.$state.customDataConfigs.delete(config);
        },
        async createCustomDataField(
            config: CustomDataConfigurationId,
            name: string,
            fieldName: string,
            type: DynamicFormSchemaItemTypes,
            options?: DynamicFormSelectItem[],
            rules?: CustomDataRule[]
        ) {
            const result = await projectConfigApi.createCustomDataField(
                config,
                name,
                fieldName,
                type,
                options,
                rules
            );
            const cfg = this.$state.customDataConfigs.get(config);
            if (cfg) {
                cfg.schema.push(result);
                this.$state.customDataConfigs.set(cfg.id, cfg);
            }
            return result;
        },
        async editCustomDataField(
            config: CustomDataConfigurationId,
            field: CustomDataFieldId,
            name?: string,
            fieldName?: string,
            type?: DynamicFormSchemaItemTypes,
            options?: DynamicFormSelectItem[],
            rules?: CustomDataRule[]
        ) {
            await projectConfigApi.editCustomDataField(
                config,
                field,
                name,
                fieldName,
                type,
                options,
                rules
            );
            const cfg = this.$state.customDataConfigs.get(config);
            if (cfg) {
                const item = cfg.schema.find((s) => s.id == field);
                if (item) {
                    if (name) item.name = name;
                    if (fieldName) item.fieldName = fieldName;
                    if (type) item.type = type;
                    if (options) item.options = options;
                    if (rules) item.rules = rules;
                    this.$state.customDataConfigs.set(cfg.id, cfg);
                }
            }
        },
        async patchCustomDataField(
            config: CustomDataConfigurationId,
            field: CustomDataFieldId,
            name?: string,
            fieldName?: string,
            type?: DynamicFormSchemaItemTypes,
            options?: DynamicFormSelectItem[],
            rules?: CustomDataRule[]
        ) {
            await this.editCustomDataField(config, field, name, fieldName, type, options, rules);
        },
        async deleteCustomDataField(config: CustomDataConfigurationId, field: CustomDataFieldId) {
            await projectConfigApi.deleteCustomDataField(config, field);
            const cfg = this.$state.customDataConfigs.get(config);
            if (cfg) {
                cfg.schema = cfg.schema.filter((s) => s.id != field);
                this.$state.customDataConfigs.set(cfg.id, cfg);
            }
        },
    },
});
